import 'regenerator-runtime/runtime';
import React, { Component } from 'react';
import WeAuth, { AuthInterface, AuthState } from '@wework/core-web-auth-sdk';
import { WeSecureWifiCredentialsInterface } from '@wework/core-web-wifi-sdk';
import defaultConfig from '../../config/development.json';

interface WelcomeState {
  uuid: string;
  accessToken: string;
  wifiCredentials: WeSecureWifiCredentialsInterface;
  auth: AuthInterface;
  isLoggedIn: boolean;
  loggedInRetrieved: boolean;
  accessTokenErrorMessage: string;
  uuidErrorMessage: string;
  credErrorMessage: string;
  signOutErrorMessage: string;
}

class Welcome extends Component<{}, WelcomeState> {
  constructor(props: {}) {
    super(props);

    if (!process.env.CLIENT_ID) {
      console.error('CLIENT_ID is not defined on process.env');
    }
    if (!process.env.REDIRECT_URI) {
      console.error('REDIRECT_URI is not defined on process.env');
    }

    const auth = new WeAuth({
      clientID: process.env.CLIENT_ID || defaultConfig.CLIENT_ID,
      redirectURI: process.env.REDIRECT_URI || defaultConfig.REDIRECT_URI,
      environment: process.env.APP_ENV || defaultConfig.APP_ENV,
    });

    this.state = {
      wifiCredentials: undefined,
      isLoggedIn: false,
      loggedInRetrieved: false,
      accessToken: '',
      uuid: '',
      auth,
      accessTokenErrorMessage: '',
      uuidErrorMessage: '',
      credErrorMessage: '',
      signOutErrorMessage: '',
    };

    this.onAuthorize = this.onAuthorize.bind(this);
    this.onSignOut = this.onSignOut.bind(this);
    this.getUUID = this.getUUID.bind(this);
    this.getAccessToken = this.getAccessToken.bind(this);
    this.getCredentials = this.getCredentials.bind(this);
  }

  public async componentDidMount() {
    let authState: AuthState;

    try {
      authState = await this.state.auth.getCurrentState();
    } catch (error) {
      console.error(error.message);
    }

    this.setState({
      isLoggedIn: authState === AuthState.SIGNED_IN,
      loggedInRetrieved: true,
    });
  }

  /**
   * @method onAuthorize
   */

  private async onAuthorize() {
    let accessToken: string;

    try {
      await this.state.auth.signIn();
      accessToken = await this.state.auth.getAccessToken();
    } catch (error) {
      console.error(error.message);
    }

    if (accessToken) {
      this.setState({ accessToken });
    }
  }

  /**
   * @method getUUID
   */

  private async getUUID() {
    let uuid: string;
    this.setState({ uuidErrorMessage: '' });

    try {
      uuid = await this.state.auth.getUUID();
    } catch (error) {
      this.setState({ uuidErrorMessage: error.message });
    }

    uuid && this.setState({ uuid });
  }

  /**
   * @method getAccessToken
   */

  private async getAccessToken() {
    let accessToken: string;
    this.setState({ accessTokenErrorMessage: '' });

    try {
      accessToken = await this.state.auth.getAccessToken();
    } catch (error) {
      this.setState({ accessTokenErrorMessage: error.message });
    }

    accessToken && this.setState({ accessToken });
  }

  /**
   * @method onSignOut
   */

  private async onSignOut() {
    this.setState({ signOutErrorMessage: '' });

    try {
      await this.state.auth.signOut();
    } catch (error) {
      this.setState({ signOutErrorMessage: error.message });
    }

    this.setState({
      isLoggedIn: false,
      loggedInRetrieved: false,
    });
  }

  /**
   * @method getCredentials
   */

  private async getCredentials() {
    let wifiCredentials;
    this.setState({ credErrorMessage: '' });

    try {
      await this.getAccessToken();

      wifiCredentials = await this.state.auth.getWifiCredentials(
        this.state.accessToken,
      );
    } catch (error) {
      this.setState({ credErrorMessage: error.message });
    }

    wifiCredentials && this.setState({ wifiCredentials });
  }

  public render() {
    const { auth, accessToken, uuid, wifiCredentials } = this.state;

    return (
      <div>
        {this.state.loggedInRetrieved && this.state.isLoggedIn ? (
          <div>
            <p>You are signed in!</p>
            {accessToken && (
              <div>
                <div>
                  <p>
                    <strong>Access token:</strong>
                  </p>
                  <input type="text" defaultValue={accessToken} />
                </div>
              </div>
            )}
            {uuid && (
              <div>
                <div>
                  <p>
                    <strong>UUID:</strong>
                  </p>
                  <p>{uuid}</p>
                </div>
              </div>
            )}
            {wifiCredentials && (
              <div>
                <p>
                  <strong>Wifi credentials</strong>
                </p>
                <div>
                  <div>
                    <strong>username:</strong>
                    <input
                      type="text"
                      defaultValue={wifiCredentials.username}
                    />
                  </div>
                  <div>
                    <strong>password:</strong>
                    <input
                      type="text"
                      defaultValue={wifiCredentials.password}
                    />
                  </div>
                  <div>
                    <strong>network:</strong>
                    <input
                      type="text"
                      defaultValue={wifiCredentials.network_name}
                    />
                  </div>
                </div>
              </div>
            )}
            <div>
              <button onClick={this.getCredentials}>Get Credentials</button>
              <button onClick={this.getUUID}>Get UUID</button>
              <button onClick={this.getAccessToken}>Get Access Token</button>
              <button onClick={this.onSignOut}>Sign Out</button>
            </div>
          </div>
        ) : (
          <div>
            <p>You are not signed in.</p>
            <div>
              <button onClick={this.onAuthorize}>Login with WeWork</button>
            </div>
          </div>
        )}
        <div>
          {this.state.uuidErrorMessage && (
            <div>{`UUID Error: ${this.state.uuidErrorMessage}`}</div>
          )}
          {this.state.credErrorMessage && (
            <div>{`Credentials Error: ${this.state.credErrorMessage}`}</div>
          )}
          {this.state.signOutErrorMessage && (
            <div>{`Sign Out Error: ${this.state.signOutErrorMessage}`}</div>
          )}
          {this.state.accessTokenErrorMessage && (
            <div>{`Access Token Error: ${this.state.accessTokenErrorMessage}`}</div>
          )}
        </div>
      </div>
    );
  }
}

export default Welcome;
