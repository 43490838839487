import axios, { AxiosInstance } from 'axios';
import {
  CredentialUtils,
  CredentialUtilsInterface,
  WeTrackEvents,
  WeTrackGroups,
} from './utils';
import {
  InitWeTrack,
  WeTrackLevel,
  WeTrackProperties,
  wetrack,
} from './utils/wetrack';
import { getEnvironment } from './helpers';

export enum WeSecureWifiErrorMessages {
  GET_CREDENTIALS_FAILURE = 'Credentials failure: unable to retrieve credentials',
}

export interface WeSecureWifiCredentialsInterface {
  username: string;
  password: string;
  network_name: string;
}

export interface WeSecureWifiOptionsInterface {
  environment?: string;
}

export interface WeSecureWifiInterface {
  getCredentials(token?: string): Promise<WeSecureWifiCredentialsInterface>;
}

/**
 * @class WeSecureWiFi
 * @description Authenticates user via WeAuth SDK to obtain
 * relevant wifi credentials
 */
class WeSecureWifi implements WeSecureWifiInterface {
  private axiosInstance: AxiosInstance;
  private credentialUtils: CredentialUtilsInterface;
  private environment: string;

  constructor(options?: WeSecureWifiOptionsInterface) {
    this.environment = getEnvironment(options && options.environment);

    InitWeTrack(this.environment);

    this.axiosInstance = axios.create();
    this.credentialUtils = new CredentialUtils(
      this.axiosInstance,
      this.environment,
    );
  }

  /**
   * @method getCredentials
   * @param accessToken required to pass in an identifying
   * access token in order to obtain user credentials
   * @description Retrieve WeWork Wifi credentials
   */
  public async getCredentials(accessToken: string) {
    let credentials: WeSecureWifiCredentialsInterface;
    const trackingInfo: WeTrackProperties = {
      name: WeTrackEvents.GET_CREDENTIALS,
      group: WeTrackGroups.WIFI_PUBLIC_API,
      level: WeTrackLevel.info,
    };

    try {
      credentials = await this.credentialUtils.fetchCredentials(accessToken);
    } catch (error) {
      wetrack.track({ ...trackingInfo, level: WeTrackLevel.error });
      throw new Error(WeSecureWifiErrorMessages.GET_CREDENTIALS_FAILURE);
    }

    if (credentials) {
      wetrack.track(trackingInfo);
    }

    return credentials;
  }
}

export default WeSecureWifi;
