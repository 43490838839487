// This is a wrapper around AppAuth's crypto library.

import { Crypto as AppAuthCrypto, DefaultCrypto } from '@openid/appauth';
import { CryptoError } from '../errors';

export interface CryptoInterface {
  deriveChallenge(codeVerifier: string): Promise<string>;
  generateRandom(size: number): string;
}

export default class Crypto {
  private crypto: AppAuthCrypto;

  constructor(Crypto = DefaultCrypto) {
    this.crypto = new Crypto();
  }

  public deriveChallenge(codeVerifier: string): Promise<string> {
    return this.crypto.deriveChallenge(codeVerifier).catch((error) => {
      throw new CryptoError(error.message);
    });
  }

  public generateRandom(size: number): string {
    return this.crypto.generateRandom(size);
  }

  public getCrypto(): AppAuthCrypto {
    return this.crypto;
  }
}
