import {
  TrackerTypes,
  WeTrack,
  WeTrackFactory,
} from '@wework/core-web-wetrack';
import config from '../../config/credentialServiceConfiguration.json';
import packageJson from '../../package.json';

export const WeTrackEvents = {
  GET_CREDENTIALS: 'get_credentials',
  EXCEPTION: 'exception',
};

export const WeTrackGroups = {
  WIFI_PUBLIC_API: 'wifi_public_api',
  ERROR: 'error_web',
};

export enum WeTrackLevel {
  info = 'info',
  error = 'error',
}

export interface WeTrackProperties {
  name: string;
  group: string;
  level: WeTrackLevel;
}

export let wetrack: WeTrack;

export const InitWeTrack = (env: string) => {
  wetrack = WeTrackFactory.init({
    trackers: [TrackerTypes.BFF],
    env,
    apiKey: config.wetrackAPIKey,
    common: {
      sdk_version_name: packageJson.name,
      sdk_version_code: packageJson.version,
      component_name: 'WeSecureWifi SDK',
      platform: 'web',
    },
  });
};
