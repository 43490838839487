import { AxiosError, AxiosInstance, AxiosRequestConfig } from 'axios';
import { TokenRequestError } from '../errors';

import { AuthTokens, AuthTokenUtils } from './auth_token_utils';

/**
 * REFRESH_TOKEN: Grant type used while retrieving a refreshed 'access token'
 * AUTH_CODE: General grant type during token exchange
 */

export enum GrantTypes {
  REFRESH_TOKEN = 'refresh_token',
  AUTH_CODE = 'authorization_code',
}

interface AuthCodeExchangeParams {
  grantType: GrantTypes;
  code: string;
  codeVerifier: string;
  redirectURI: string;
  clientID: string;
}

interface TokenRefreshParams {
  grantType: GrantTypes;
  refreshToken: string;
}

interface AuthCodeExchangeData {
  grant_type: GrantTypes;
  code: string;
  code_verifier: string;
  redirect_uri: string;
  client_id: string;
}

interface RefreshTokensData {
  grant_type: GrantTypes;
  refresh_token: string;
}

export enum TokenRequestErrorMessages {
  TOKEN_REQUEST_ERROR = 'Failed to make token request.',
}

export interface RequestUtilsInterface {
  exchangeCode(
    authCodeExchangeParams: AuthCodeExchangeParams,
  ): Promise<AuthTokens>;
  refreshTokens(tokenRefreshParams: TokenRefreshParams): Promise<AuthTokens>;
}

export class TokenRequestUtils implements RequestUtilsInterface {
  public static formatAxiosError(error: AxiosError) {
    if (error.response) {
      // Response made but server exited with status code outside of 2xx
      return new TokenRequestError(
        TokenRequestErrorMessages.TOKEN_REQUEST_ERROR,
        error.response.status,
        error.response.data,
      );
    } else if (error.request) {
      return new TokenRequestError(
        TokenRequestErrorMessages.TOKEN_REQUEST_ERROR,
        undefined,
        undefined,
        error.request,
      );
    } else {
      return new TokenRequestError(
        TokenRequestErrorMessages.TOKEN_REQUEST_ERROR,
      );
    }
  }

  private axios: AxiosInstance;
  private axiosRequestConfig: AxiosRequestConfig;

  constructor(
    axiosInstance: AxiosInstance,
    axiosRequestConfig: AxiosRequestConfig,
  ) {
    this.axios = axiosInstance;
    this.axiosRequestConfig = axiosRequestConfig;
  }

  public exchangeCode(authCodeExchangeParams: AuthCodeExchangeParams) {
    const data = {
      grant_type: authCodeExchangeParams.grantType,
      code: authCodeExchangeParams.code,
      code_verifier: authCodeExchangeParams.codeVerifier,
      redirect_uri: authCodeExchangeParams.redirectURI,
      client_id: authCodeExchangeParams.clientID,
    };

    return this.makeTokenRequest(data);
  }

  public async refreshTokens(tokenRefreshParams: TokenRefreshParams) {
    const data = {
      grant_type: tokenRefreshParams.grantType,
      refresh_token: tokenRefreshParams.refreshToken,
    };

    return this.makeTokenRequest(data);
  }

  public async makeTokenRequest(
    authData: AuthCodeExchangeData | RefreshTokensData,
  ) {
    const requestConfig = {
      ...this.axiosRequestConfig,
      method: 'post',
      data: authData,
    };

    try {
      const { data } = await this.axios.request(requestConfig);
      return AuthTokenUtils.getFormattedAuthTokens(data);
    } catch (error) {
      throw TokenRequestUtils.formatAxiosError(error);
    }
  }
}
