import { LocalStorageBackend } from '@openid/appauth';
import { StorageError } from '../errors';

import StorageUtils, { StorageType } from './storage_utils';

export interface StorageInterface {
  getItem(name: string): Promise<any>;
  setItem(name: string, value: any): Promise<void>;
  removeItem(name: string): Promise<void>;
  clear(): Promise<void>;
}

export enum StorageErrorMessages {
  GET_ITEM_FAILURE = 'Failed to get item from storage.',
  SET_ITEM_FAILURE = 'Failed to set item in storage.',
  REMOVE_ITEM_FAILURE = 'Failed to remove item from storage.',
  CLEAR_STORAGE_FAILURE = 'Failed to clear storage.',
}

export default class Storage implements StorageInterface {
  private storage: LocalStorageBackend;
  private storageType: StorageType;
  private namespace: string;

  constructor(namespace: string, storageType: StorageType = 'local') {
    this.storage = StorageUtils.getLocalStorageBackend(storageType);
    this.storageType = storageType;
    this.namespace = namespace;
  }

  public getItem(name: string): Promise<any> {
    const key = this.getNamespacedName(name);
    return this.storage
      .getItem(key)
      .then(StorageUtils.parseStoredObject)
      .catch(() => {
        throw new StorageError(
          StorageErrorMessages.GET_ITEM_FAILURE,
          this.storageType,
          key,
        );
      });
  }

  public setItem(name: string, value: any): Promise<void> {
    const key = this.getNamespacedName(name);
    return this.storage
      .setItem(this.getNamespacedName(name), JSON.stringify(value))
      .catch(() => {
        throw new StorageError(
          StorageErrorMessages.SET_ITEM_FAILURE,
          this.storageType,
          key,
        );
      });
  }

  public removeItem(name: string): Promise<void> {
    const key = this.getNamespacedName(name);
    return this.storage.removeItem(key).catch(() => {
      throw new StorageError(
        StorageErrorMessages.REMOVE_ITEM_FAILURE,
        this.storageType,
        key,
      );
    });
  }

  public clear(): Promise<void> {
    return this.storage.clear().catch(() => {
      throw new StorageError(
        StorageErrorMessages.CLEAR_STORAGE_FAILURE,
        this.storageType,
      );
    });
  }

  private getNamespacedName(name: string) {
    return `${this.namespace}/${name}`;
  }
}
