import { wetrack, WeTrackEvents, WeTrackGroups } from './utils/wetrack';

export class WeAuthError extends Error {
  public name: string;

  constructor(public message: string) {
    super();

    this.name = 'we_auth_error';
    this.message = message;

    if (wetrack) {
      wetrack.track({
        name: WeTrackEvents.EXCEPTION,
        group: WeTrackGroups.ERROR,
        level: 'info',
        properties: {
          reason: message,
        },
      });
    }
  }
}

export class CryptoError extends WeAuthError {
  public static errorName = 'we_auth_crypto_error';
  constructor(public message: string) {
    super(message);

    this.name = CryptoError.errorName;
  }
}

export class AuthorizationRequestError extends WeAuthError {
  public static errorName = 'we_auth_authorization_error';
  constructor(public message: string, public errorCode: string) {
    super(message);

    this.name = AuthorizationRequestError.errorName;
    this.errorCode = errorCode;
  }
}

export class ValidationError extends WeAuthError {
  public static errorName = 'we_auth_validation_error';
  constructor(public message: string) {
    super(message);

    this.name = ValidationError.errorName;
  }
}

export class RedirectURIValidationError extends ValidationError {
  public static errorName = 'we_auth_redirect_uri_validation_error';
  constructor(public message: string, public parameter: string) {
    super(message);

    this.name = RedirectURIValidationError.errorName;
    this.parameter = parameter;
  }
}

export class StorageError extends WeAuthError {
  public static errorName = 'we_auth_storage_error';
  public storageType?: string;
  public key?: string;

  constructor(public message: string, storageType?: string, key?: string) {
    super(message);

    this.name = StorageError.errorName;
    this.storageType = storageType;
    this.key = key;
  }
}

export class TokenRequestError extends WeAuthError {
  public static errorName = 'we_auth_token_request_error';
  constructor(
    public message: string,
    public status?: number,
    public data?: any,
    public request?: any,
  ) {
    super(message);

    this.name = TokenRequestError.errorName;
    this.status = status;
    this.data = data;
    this.request = request;
  }
}
