import qs from 'qs';

import {
  AuthorizationRequestError,
  RedirectURIValidationError,
} from '../errors';

export interface AuthResult {
  code: string;
  state: string;
}

export enum RedirectURIQueryParams {
  ERROR_CODE = 'error_code',
  CODE = 'code',
  STATE = 'state',
}

export enum RedirectURIValidationErrorMessages {
  BAD_REQUEST = 'The authorization request parameters are invalid.',
  MISSING_CODE = 'Query string does not contain a code.',
  MISSING_STATE = 'Query string does not contain a state.',
}

export class RedirectURIHandler {
  public static parseRedirectQueryString(queryString: string): AuthResult {
    const parsedQueryString = qs.parse(queryString, {
      ignoreQueryPrefix: true,
    });

    if (parsedQueryString.hasOwnProperty(RedirectURIQueryParams.ERROR_CODE)) {
      throw new AuthorizationRequestError(
        RedirectURIValidationErrorMessages.BAD_REQUEST,
        parsedQueryString.error_code as string,
      );
    }

    if (!parsedQueryString.hasOwnProperty(RedirectURIQueryParams.CODE)) {
      throw new RedirectURIValidationError(
        RedirectURIValidationErrorMessages.MISSING_CODE,
        RedirectURIQueryParams.CODE,
      );
    }

    if (!parsedQueryString.hasOwnProperty(RedirectURIQueryParams.STATE)) {
      throw new RedirectURIValidationError(
        RedirectURIValidationErrorMessages.MISSING_STATE,
        RedirectURIQueryParams.STATE,
      );
    }

    return {
      code: parsedQueryString.code as string,
      state: parsedQueryString.state as string,
    };
  }

  public static addQueryParam(
    redirectURI: string,
    paramKey: string,
    paramValue: string,
  ) {
    const url = new URL(redirectURI);
    url.searchParams.append(paramKey, paramValue);
    return url.href;
  }
}
