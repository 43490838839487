import { createBrowserHistory as createHistory } from 'history';
import React from 'react';
import { Redirect, Route, Router, Switch } from 'react-router-dom';
import Login from './Login';
import Welcome from './Welcome';

const App = () => (
  <div>
    <header>
      <h1>WeWork Auth SDK Example</h1>
    </header>
    <Router history={createHistory()}>
      <Switch>
        <Route component={Welcome} path="/welcome" />
        <Route component={Login} path="/callback" />
        <Redirect to="/welcome" />
      </Switch>
    </Router>
  </div>
);

export default App;
