import debug$1 from 'debug';

const Envs = {
    Production: 'production',
    Staging: 'staging',
    Development: 'development',
};
const TrackerTypes = {
    BFF: 'bff',
    Segment: 'segment',
};
const DefaultTrackers = [TrackerTypes.BFF];
const BFFPublishUrls = {
    [Envs.Production]: 'https://ce-analytics-bff.hydra.prod.wwrk.co/api/v1/events',
    [Envs.Staging]: 'https://ce-analytics-bff.phoenix.dev.wwrk.co/api/v1/events',
    [Envs.Development]: 'http://localhost:3000/api/v1/events',
};
const EVENT_BUFFER_LIMIT = 20;
const FLUSH_TIMEOUT = 10000;
const analyticsMethods = [
    'trackSubmit',
    'trackClick',
    'trackLink',
    'trackForm',
    'pageview',
    'identify',
    'reset',
    'group',
    'track',
    'ready',
    'alias',
    'debug',
    'page',
    'once',
    'off',
    'on',
];

var debug = debug$1('wetrack');

const READY_STATE_DONE = 4;
class Http {
    static post(url, payload) {
        return new Promise((resolve, reject) => {
            const http = new XMLHttpRequest();
            http.open('POST', url);
            http.setRequestHeader('Content-Type', 'application/json;charset=UTF-8');
            http.onreadystatechange = () => {
                if (http.readyState === READY_STATE_DONE) {
                    if (http.status === 200) {
                        return resolve();
                    }
                    if (http.status >= 400 && http.status < 600) {
                        return reject({ status: http.status, message: http.responseText });
                    }
                }
            };
            http.send(JSON.stringify(payload));
        });
    }
}

class BFFTracker {
    constructor(props) {
        this.name = 'bff';
        this.publishUrl = BFFPublishUrls[props.env || Envs.Production];
        this.apiKey = props.apiKey;
        this.common = props.common;
    }
    publish(events) {
        const data = this.formatEventsData(events);
        Http.post(this.publishUrl, data)
            .then(() => {
            debug(`Successfully published to tracker: ${this.name}`);
        })
            .catch((e) => {
            debug(`Unable to publish to tracker: ${this.name}`);
            debug(e);
        });
    }
    formatEventsData(events) {
        return {
            apiKey: this.apiKey,
            common: this.common,
            events,
        };
    }
}

const SNIPPET_VERSION = '4.1.0';
const createSegmentPayload = (name, group, level, info) => ({
    name,
    group,
    level,
    properties: { info },
});
class SegmentTracker {
    constructor(props) {
        this.publish = (eventBuffer) => {
            for (const event of eventBuffer) {
                this.publishEvent(event);
            }
        };
        this.publishEvent = (event) => {
            if (!event.properties) {
                this.analytics.track(event.name, {
                    group: event.group,
                    level: event.level,
                    timestamp: event.timestamp,
                });
                return;
            }
            const { options, info, pageName, pageCategory, userId, groupId, traits, } = event.properties;
            const properties = Object.assign(Object.assign({}, info), this.common);
            try {
                switch (event.group) {
                    case 'identify':
                        this.analytics.identify(userId, traits, options);
                        break;
                    case 'group':
                        if (groupId) {
                            this.analytics.group(groupId, traits, options);
                        }
                        else {
                            debug('Required info missing. Please include groupId');
                            // TODO: Add option to route error to Rollbar
                        }
                        break;
                    case 'page':
                        this.analytics.page(pageCategory, pageName, properties, options);
                        break;
                    case 'track':
                        this.analytics.track(event.name, properties, options);
                        break;
                }
            }
            catch (e) {
                debug('Unable to post event to Segment: ' + e);
                // TODO: Add option to route error to Rollbar
            }
        };
        this.updateAnalytics = () => {
            if (!this.analytics.initialize) {
                this.analytics = window.analytics;
            }
        };
        this.init = () => {
            if (this.analytics.initialize) {
                return;
            }
            if (this.analytics.invoked) {
                debug('Segment snippet included twice.');
                // TODO: Add option to route error to Rollbar
                return;
            }
            this.analytics.invoked = true;
            this.analytics.methods = analyticsMethods;
            this.analytics.factory = (method, ...args) => {
                return () => {
                    this.analytics.push([method, ...args]);
                    return this.analytics;
                };
            };
            for (const method of this.analytics.methods) {
                this.analytics[method] = this.analytics.factory(method);
            }
            this.analytics.load = (key, options) => {
                const script = document.createElement('script');
                script.type = 'text/javascript';
                script.async = true;
                script.onload = this.updateAnalytics;
                script.src =
                    'https://cdn.segment.com/analytics.js/v1/' + key + '/analytics.min.js';
                const first = document.getElementsByTagName('script')[0];
                if (first && first.parentNode) {
                    first.parentNode.insertBefore(script, first);
                    this.analytics._loadOptions = options;
                }
            };
            this.analytics.SNIPPET_VERSION = SNIPPET_VERSION;
            this.analytics.load(this.apiKey);
            this.analytics.page('Grant HQ');
        };
        window.analytics = [];
        this.analytics = window.analytics;
        this.common = props.common;
        this.apiKey = props.apiKey;
        if (this.apiKey) {
            this.init();
        }
    }
}

const trackers = {
    [TrackerTypes.BFF]: BFFTracker,
    [TrackerTypes.Segment]: SegmentTracker,
};
class WeTrack {
    constructor(props) {
        this.eventBuffer = [];
        this.validate(props);
        this.env = (props.env || Envs.Production);
        this.apiKey = props.apiKey;
        this.common = props.common;
        this.disableBatching = Boolean(props.disableBatching);
        this.trackers = this.initTrackers(props);
        if (!this.disableBatching) {
            this.startFlushTimeout();
        }
        if (window !== undefined) {
            window.addEventListener('beforeunload', () => {
                this.flushEvents();
            });
        }
    }
    track(event) {
        debug(`Tracking event: ${event.name}`);
        const unixSeconds = Math.floor(new Date().getTime() / 1000);
        const timestamp = String(unixSeconds);
        this.eventBuffer.push(Object.assign(Object.assign({}, event), { timestamp }));
        if (this.shouldFlush() || this.disableBatching) {
            this.flushEvents();
        }
    }
    startFlushTimeout() {
        this.activeFlushTimeout = setTimeout(() => {
            this.flushEvents();
        }, FLUSH_TIMEOUT);
    }
    resetFlushTimeout() {
        clearTimeout(this.activeFlushTimeout);
        this.startFlushTimeout();
    }
    makeTracker(trackerType) {
        if (!getObjectValues(TrackerTypes).includes(trackerType)) {
            return null;
        }
        return new trackers[trackerType]({
            env: this.env,
            common: this.common,
            apiKey: this.apiKey,
        });
    }
    initTrackers(props) {
        if (!props.trackers || props.trackers.length === 0) {
            return DefaultTrackers.map((trackerKey) => {
                return new trackers[trackerKey](Object.assign(Object.assign({}, props), { env: this.env }));
            });
        }
        return props.trackers.reduce((acc, trackerType) => {
            const tracker = this.makeTracker(trackerType);
            if (tracker === null) {
                return acc;
            }
            return [...acc, tracker];
        }, []);
    }
    shouldFlush() {
        return this.eventBuffer.length >= EVENT_BUFFER_LIMIT;
    }
    flushEvents() {
        const numEvents = this.eventBuffer.length;
        debug(`Attempting to flush ${numEvents} event${getPluralString(numEvents)}`);
        if (this.eventBuffer.length > 0) {
            this.trackers.forEach((tracker) => {
                tracker.publish(this.eventBuffer);
            });
            this.eventBuffer = [];
        }
        if (!this.disableBatching) {
            this.resetFlushTimeout();
        }
    }
    checkEnv({ env }) {
        checkStringAllowed(env, Envs);
    }
    checkTrackers({ trackers }) {
        if (trackers) {
            trackers.forEach((tracker) => {
                checkStringAllowed(tracker, TrackerTypes);
            });
        }
    }
    validate(props) {
        this.checkEnv(props);
        this.checkTrackers(props);
    }
}
function getPluralString(length) {
    return length === 1 ? '' : 's';
}
function getObjectValues(obj) {
    return Object.keys(obj).map((e) => obj[e]);
}
function checkStringAllowed(s, obj) {
    const allowedValues = getObjectValues(obj);
    const allowedStrings = allowedValues.join('|');
    if (!allowedValues.includes(s)) {
        throw new Error(`Supplied env must be one of (${allowedStrings}).`);
    }
}

class WeTrackFactory {
    static init(props) {
        return new WeTrack(props);
    }
}

var SegmentGroups;
(function (SegmentGroups) {
    SegmentGroups["TRACK"] = "track";
    SegmentGroups["IDENTIFY"] = "identify";
    SegmentGroups["GROUP"] = "group";
    SegmentGroups["PAGE"] = "page";
})(SegmentGroups || (SegmentGroups = {}));
var SegmentLevels;
(function (SegmentLevels) {
    SegmentLevels["INFO"] = "info";
    SegmentLevels["ERROR"] = "error";
})(SegmentLevels || (SegmentLevels = {}));

export { SegmentGroups, SegmentLevels, TrackerTypes, WeTrack, WeTrackFactory, createSegmentPayload };
