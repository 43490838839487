import authConfiguration from '../../config/authorizationServiceConfiguration.json';

export enum Environment {
  STAGING = 'staging',
  PRODUCTION = 'production',
}

/**
 * getEnvironment
 * @description Return the appropriate environment enum, if available.
 * Default to production environment if a match isn't found.
 * @param env Client given environment string
 */

export const getEnvironment = (env?: string) => {
  if (!env || !Object.values(Environment).includes(env as Environment)) {
    return Environment.PRODUCTION;
  }

  return env;
};

/**
 * getConfigAuthURL
 * @description Return the appropriate auth url for given environment
 * @param env Given environment string
 */

export const getConfigAuthURL = (env?: string) =>
  env === Environment.STAGING
    ? authConfiguration.stagingAuthURL
    : authConfiguration.prodURL;

/**
 * getConfigBaseURL
 * @description Return the appropriate base url for given environment
 * @param env Given environment string
 */

export const getConfigBaseURL = (env?: string) =>
  env === Environment.STAGING
    ? authConfiguration.stagingBaseURL
    : authConfiguration.prodURL;
