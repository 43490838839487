/**
 * Using hue values to pass as query
 * param values for theming
 */
export enum StyleStates {
  RAY = 240,
  DIETER = 205,
  TANOOKI = 338,
}

/**
 * getStyleCode
 * @description Returns specified style given on instantiation,
 * otherwise fall back on Tanooki styles
 *
 * TODO: Should be changed to Ray as the default style,
 * this current default is to prevent breaking HPRoam team theming
 *
 * @param theme Client given style enum
 */
export const getStyleCode = (theme?: string) => {
  let themeCode: number;

  switch (theme) {
    case 'ray':
      themeCode = StyleStates.RAY;
      break;
    case 'dieter':
      themeCode = StyleStates.DIETER;
      break;
    default:
      themeCode = StyleStates.TANOOKI;
  }

  return themeCode;
};
