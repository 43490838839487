import jwtDecode from 'jwt-decode';
import { WeAuthError } from '../errors';
import { StorageInterface } from '../storage';

export interface AuthResponse {
  access_token: string;
  expires_in?: number;
  refresh_token: string;
  id_token: string;
  token_type: string;
}

export interface AuthTokens {
  accessToken: string;
  uuid?: string;
  refreshToken: string;
  expiration: Date;
}

export enum AuthTokenUtilsErrorMessages {
  STORAGE_RETRIEVAL_ERROR = 'Failed to get auth tokens from storage',
  CLEAR_TOKENS_ERROR = 'Failed to clear auth tokens in storage',
  JWT_DECODE_ERROR = 'Failed to read id token',
}

export const DEFAULT_EXPIRES_IN_SEC = 60 * 60 * 18; // 18 hours, in seconds

export class AuthTokenUtils {
  public static getFormattedAuthTokens(authResponse: AuthResponse): AuthTokens {
    const {
      expires_in: expiresIn,
      access_token: accessToken,
      refresh_token: refreshToken,
      id_token: idToken,
    } = authResponse;

    // In the case where expires_in does not exist, default to now + 18 hours
    const adjustedExpiresIn = expiresIn ? expiresIn : DEFAULT_EXPIRES_IN_SEC;
    const expiration = new Date(Date.now() + adjustedExpiresIn * 1000);
    const uuid = AuthTokenUtils.decodeIdToken(idToken);

    const authTokens = {
      accessToken,
      refreshToken,
      expiration,
    };

    return uuid ? { ...authTokens, uuid } : authTokens;
  }

  /**
   * Check if auth tokens are expired.
   * @param authTokens
   * @param offsetSeconds - expiration offset, in seconds
   */
  public static isAuthTokensExpired(authTokens: AuthTokens, offsetSeconds = 0) {
    const { expiration } = authTokens;
    const now = new Date(Date.now());

    // Move expiration closer by buffer
    const adjustedExpiration = new Date(
      expiration.getTime() - offsetSeconds * 1000,
    );

    return adjustedExpiration <= now;
  }

  public static async getAuthTokensFromStorage(
    storage: StorageInterface,
    key: string,
  ): Promise<AuthTokens | null> {
    let authTokens;

    try {
      authTokens = await storage.getItem(key);
    } catch (error) {
      throw new WeAuthError(
        AuthTokenUtilsErrorMessages.STORAGE_RETRIEVAL_ERROR,
      );
    }

    // Return null if auth tokens do not exist in local storage
    if (!authTokens) {
      return null;
    }

    return {
      accessToken: authTokens.accessToken,
      refreshToken: authTokens.refreshToken,
      uuid: authTokens.uuid,
      expiration: new Date(authTokens.expiration),
    };
  }

  public static async clearAuthTokens(
    storage: StorageInterface,
    key: string,
  ): Promise<void> {
    const authTokens = await AuthTokenUtils.getAuthTokensFromStorage(
      storage,
      key,
    );

    try {
      if (authTokens) {
        await storage.removeItem(key);
      }
    } catch (error) {
      throw new WeAuthError(AuthTokenUtilsErrorMessages.CLEAR_TOKENS_ERROR);
    }
  }

  /**
   * Decodes given jwt id token (openid connect) to retrieve uuid
   * @param idToken
   */

  public static decodeIdToken(idToken: string) {
    let uuid;

    if (idToken) {
      try {
        uuid = (jwtDecode(idToken) as any).sub;
      } catch (e) {
        throw new WeAuthError(AuthTokenUtilsErrorMessages.JWT_DECODE_ERROR);
      }
    }

    return uuid;
  }
}
