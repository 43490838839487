import { LocalStorageBackend } from '@openid/appauth';

export type StorageType = 'local' | 'session';

export default class StorageUtils {
  public static getNamespacedKey(key: string, namespace: string): string {
    return `${namespace}/${key}`;
  }

  public static parseStoredObject(value: string | null): any {
    if (!value) {
      return null;
    } else {
      return JSON.parse(value);
    }
  }

  public static getLocalStorageBackend(
    storageType: StorageType,
  ): LocalStorageBackend {
    if (storageType === 'local') {
      return new LocalStorageBackend(window.localStorage);
    } else {
      return new LocalStorageBackend(window.sessionStorage);
    }
  }
}
