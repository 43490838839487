import 'regenerator-runtime/runtime';
import WeAuth from '@wework/core-web-auth-sdk';
import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import defaultConfig from '../../config/development.json';

interface LoginState {
  authCode: string;
  loggedIn: boolean;
  loginError: string;
}

class Login extends Component<RouteComponentProps, LoginState> {
  public constructor(props: RouteComponentProps) {
    super(props);

    this.state = {
      authCode: '',
      loggedIn: false,
      loginError: '',
    };
  }

  public async componentDidMount() {
    if (!process.env.CLIENT_ID) {
      console.error('CLIENT_ID is not defined on process.env');
    }

    if (!process.env.REDIRECT_URI) {
      console.error('REDIRECT_URI is not defined on process.env');
    }

    const auth = new WeAuth({
      clientID: process.env.CLIENT_ID || defaultConfig.CLIENT_ID,
      redirectURI: process.env.REDIRECT_URI || defaultConfig.REDIRECT_URI,
      environment: process.env.APP_ENV || defaultConfig.APP_ENV,
    });

    let redirectResponse;

    try {
      redirectResponse = await auth.handleRedirect();
    } catch (error) {
      this.setState({
        loggedIn: false,
        loginError: error.message,
      });
    }

    if (redirectResponse.state) {
      this.props.history.push('/welcome');
    }
  }

  public render() {
    const loginError = `Error: ${this.state.loginError}`;

    return (
      <div>
        <div>{this.state.loginError && loginError}</div>
      </div>
    );
  }
}

export default Login;
