import { ValidationError } from '../errors';

export interface AuthSession {
  state: string;
  codeVerifier: string;
}

export enum AuthSessionUtilsErrorMessages {
  INVALID_STATE = 'Received state parameter does not match stored state.',
}

export class AuthSessionUtils {
  /**
   * Validate that the stored state (authSession) matches the given state.
   * @param authSession
   * @param state
   */
  public static validateAuthSession(
    authSession: AuthSession,
    state: string,
  ): AuthSession {
    if (state !== authSession.state) {
      throw new ValidationError(AuthSessionUtilsErrorMessages.INVALID_STATE);
    }

    return authSession;
  }
}
