import { AxiosInstance, AxiosResponse, AxiosRequestConfig } from 'axios';
import { WeSecureWifiCredentialsInterface } from '../index';
import { getEnvironment, getConfigCredURL } from '../helpers';

import config from '../../config/credentialServiceConfiguration.json';

export interface CredentialUtilsInterface {
  fetchCredentials(
    accessToken: string,
  ): Promise<WeSecureWifiCredentialsInterface>;
}

/**
 * @class CredentialUtils
 * @description Service util for retrieving WeWork wifi credentials
 */
export class CredentialUtils implements CredentialUtilsInterface {
  private axiosInstance: AxiosInstance;
  private environment: string;

  /**
   * @method constructor
   * @param axiosInstance
   * @param environment one of production or staging
   */

  constructor(axiosInstance: AxiosInstance, environment?: string) {
    this.axiosInstance = axiosInstance;
    this.environment = getEnvironment(environment);
  }

  /**
   * @method fetchCredentials
   * @param accessToken required to pass in an identifying
   * access token in order to obtain user credentials
   * @description Retrieve the end user's WeWork wifi credentials
   * given the appropriate authentication access token
   */
  public async fetchCredentials(accessToken: string) {
    const requestConfig: AxiosRequestConfig = {
      method: 'GET',
      url: config.credEndpoint,
      baseURL: getConfigCredURL(this.environment),
      headers: {
        Authorization: `Bearer ${accessToken}`,
        ...config.defaultHeaders,
      },
    };

    let credentials: AxiosResponse;

    try {
      credentials = await this.axiosInstance.request(requestConfig);
    } catch (error) {
      throw new Error(error.message);
    }

    return credentials && credentials.data;
  }
}

export default CredentialUtils;
